<template>
  <div class="m-2">
    <h1>Ops</h1>
    <div>
      <p>{{ videos.length }} videos<br />
        {{ links.length }} links<br />
        {{ visits.length }} visits
      </p>
      <b-button @click="fixData">Update Data</b-button>
    </div>
  </div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("vid-ops");
import { db } from "@/services/db";

export default {
  data() {
    return {
      videos: [],
      links: [],
      visits: [],
    }
  },
  mounted() {
    this.$bind("videos", db.collection("S2Videos"));
    this.$bind("links", db.collection("S2Links"));
    this.$bind("visits", db.collection("S2Visits"));
  },
  methods: {
    fixData() {
      log.log("fixData");
      let updated = 0;
      // for every video in S2Videos, if it does not have ownerId, set ownerId to the ownerId of this account
      for (let video of this.videos) {
        if (!video.ownerId) {
          db.collection("S2Videos").doc(video.id).update({ ownerId: this.$store.account.uid });
          updated += 1;
        }
        if (!video.type) {
          db.collection("S2Videos").doc(video.id).update({ type: "video" });
          updated += 1;
        }
      }
      log.log("videos updated", updated);
      // for every link in S2Links, if it does not have ownerId, set ownerId to the ownerId of the video
      updated = 0;
      for (let link of this.links) {
        if (!link.ownerId) {
          let video = this.videos.find(v => v.id == link.videoId);
          if (video?.ownerId) {
            db.collection("S2Links").doc(link.id).update({ ownerId: video.ownerId });
            updated += 1;
          }
        }
        // Add missing created date to links
        if (!link.created) {
          let video = this.videos.find(v => v.id == link.videoId);
          if (video?.created) {
            db.collection("S2Links").doc(link.id).update({ created: video.created });
            updated += 1;
          }
        }
      }
      log.log("links updated", updated);
      // same for S2Visits
      updated = 0;
      for (let visit of this.visits) {
        if (!visit.ownerId) {
          let link = this.links.find(l => l.id == visit.linkId);
          if (link?.ownerId) {
            db.collection("S2Visits").doc(visit.id).update({ ownerId: link.ownerId });
            updated += 1;
          }
        }
      }
      log.log("visits updated", updated);
    }
  }
}
</script>

<style lang="scss" scoped></style>